<template>
    <el-tooltip placement="right" effect="light">
        <div slot="content">编号:{{sensorinfo.sensorcode}}#
            <br/>温度:
            <span v-show="Boolean(sensorinfo.flag&0b0011)" style="color:red">{{sensorinfo.temperature}}</span>
            <span v-show="!Boolean(sensorinfo.flag&0b0011)">{{sensorinfo.temperature}}</span> ℃
            <br/>湿度:
            <span v-show="Boolean(sensorinfo.flag&0b1100)" style="color:red">{{sensorinfo.humidity}}</span>
            <span v-show="!Boolean(sensorinfo.flag&0b1100)">{{sensorinfo.humidity}}</span> %
            <br/>区域:{{sensorinfo.warehousename }}
            <br/>时间:{{sensorinfo.updatetime}}
        </div>
        <div :id="sensorinfo.sensorid" class="sensor" v-bind:style="{ left: sensorinfo.locationx + 'px', top: sensorinfo.locationy + 'px' }">
            <span style="float:left;">{{sensorinfo.sensorcode}}</span>
        </div>
    </el-tooltip>
</template>

<script>
export default {
  props: {
    sensorinfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // disabled: true
    };
  }
};
</script>
<style>
.sensor {
  height: 23px;
  line-height: 23px;
  width: 45px;
  padding: 0px;
  position: absolute;
  background: url(../assets/green.png) no-repeat right top;
  cursor: move;
  text-align: center;
  vertical-align: middle;
  z-index: 100;
}
</style>