<template>
  <div>
    <el-dialog title="上传测点分布图" :visible.sync="uploadVisible" :close-on-click-modal="false" style="text-align:center">
      <VueImgInputer v-model="uploadImg" icon="img"></VueImgInputer>
      <p></p>
      <el-button type="primary" @click="submitImage">上传</el-button>
    </el-dialog>
    <el-form>
      <el-col :span="24" class="toolbar">
        <el-form-item>
          <p style="line-height:25px;">测点分布图展示的是各个设备中的传感器在所在库房中的精确位置，上传后以便于更好的了解个点实时温湿度状况，所以请确保分布图的精确性。上传注意点：<br>
            <span class="danger">①、上传平面图只能是 JPG/PNG 格式!</span>
            <br>
            <span class="danger"> ②、上传平面图大小不能超过 2MB!</span>
            <br>
          </p>
          <el-button @click="uploadImage" type="info">上传测点分布图</el-button>
          <el-button @click="resetDistribute">重置</el-button>
          <hr>
        </el-form-item>
        <el-form-item>
          <div id="map" style="">
            <template v-for="item in sensorslist">
              <sensor-child :sensorinfo.sync="item" :key="item.sensorid"></sensor-child>
            </template>
          </div>
        </el-form-item>
      </el-col>
    </el-form>
  </div>
</template>
<script>
import $ from "jquery";
import sensorChild from "../../components/sensorchild";
import VueImgInputer from "vue-img-inputer";
import { mapGetters } from "vuex";
export default {
  components: {
    sensorChild,
    VueImgInputer
  },
  data() {
    return {
      rtsocket: null,
      uploadVisible: false,
      uploadImg: "",
      imageUrl: "",
      sensorslist: []
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid"])
  },
  methods: {
    destroySocket: function() {
      this.rtsocket.close();
    },
    uploadImage() {
      this.uploadVisible = true;
    },
    resetDistribute() {
      this.$confirm("确认重置所有传感器的位置吗？", "提示", {}).then(() => {
        this.axios
          .post("/detection/resetsensorlocation", {
            customerid: this.customerid,
            deviceid: this.$route.params.deviceid
          })
          .then(res => {
            if (res.data.code === 0) {
              this.getSensorsList();
            } else {
              this.$message({
                message: `重置传感器位置出错，错误码${
                  res.data.code
                },请联系管理员`,
                type: "warning"
              });
            }
          });
      });
    },
    submitImage: function() {
      if (this.beforeAvatarUpload(this.uploadImg)) {
        var that = this;
        let formData = new FormData(); //创建form对象
        formData.append("file", this.uploadImg, this.uploadImg.name); //通过append向form对象添加数据
        formData.append("customerid", this.customerid);
        formData.append("deviceid", this.$route.params.deviceid);
        let config = {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        };
        that.axios
          .post("/detection/uploadimage", formData, config)
          .then(response => {
            if (response.data.code == 0) {
              that.imageUrl = response.data.values.filename;
              that.uploadVisible = false;
              let img = new Image();
              // img.src = `http://verify.coldcloud.com/uploads/${that.imageUrl}`
              img.src = `/uploads/${that.imageUrl}`;
              img.onload = function() {
                let w = img.width;
                let h = img.height;
                $("#map").css({
                  height: h + "px",
                  width: w + "px",
                  "background-image": "url(" + img.src + ")"
                });
              };
            }
          });
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传平面图只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传平面图大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    getSensorsList() {
      this.axios
        .post("/detection/getsensors", {
          customerid: this.customerid,
          deviceid: this.$route.params.deviceid
        })
        .then(res => {
          if (res.data.code === 0) {
            let data = res.data.values.sensorslist;
            data.map(sensor => {
              return Object.assign(sensor, {
                temperature: "--",
                humidity: "--",
                updatetime: "--:--:--",
                flag: 0
              });
            });
            this.sensorslist = data;
          } else {
            this.$message({
              message: `查询传感器列表出错，错误码${
                res.data.code
              },请联系管理员`,
              type: "warning"
            });
          }
        });
    }
  },
  mounted() {
    let that = this;
    that.axios
      .post("/detection/getimage", {
        customerid: that.customerid,
        deviceid: that.$route.params.deviceid
      })
      .then(res => {
        if (res.data.code === 0) {
          that.imageUrl = res.data.values.filename;
          let img = new Image();
          // img.src = `http://verify.coldcloud.com/uploads/${that.imageUrl}`
          img.src = `/uploads/${that.imageUrl}`;
          img.onload = function() {
            let w = img.width;
            let h = img.height;
            $("#map").css({
              height: h + "px",
              width: w + "px",
              "background-image": "url(" + img.src + ")"
            });
          };
        }
      });

    that.getSensorsList();

    var _move = false; //移动标记
    var _x, _y; //鼠标离控件左上角的相对位置

    var xx, yy;

    var id;
    // 普通用户无权限拖动
    if (that.roletype < 5) {
      $("#map")
        .mousedown(function(e) {
          if (!e) {
            e = window.event;
          } else {
            e.srcElement = e.target;
          }
          if (e.srcElement.id != null) {
            id = "#" + e.srcElement.id;
          }
          if (id != "#map" && id != "#" && id != "") {
            _move = true;
            _x = e.pageX - parseInt($(id).css("left"));
            _y = e.pageY - parseInt($(id).css("top"));
            $(id).fadeTo(20, 0.5); //点击后开始拖动并透明显示
            $(id).css("border", "dotted 1px #0094ff");
          }
        })
        .mousemove(function(e) {
          if (id != "#map" && id != "#" && id != "") {
            if (_move) {
              var x = e.pageX - _x; //移动时根据鼠标位置计算控件左上角的绝对位置
              var y = e.pageY - _y;
              $(id).css({
                top: y,
                left: x
              }); //控件新位置
              xx = x;
              yy = y;
            }
          }
        })
        .mouseup(function(e) {
          var clickId;
          if (!e) {
            e = window.event;
          } else {
            e.srcElement = e.target;
          }
          if (e.srcElement.id != null) clickId = e.srcElement.id;
          if (id != "#map" && id != "#" && id != "") {
            _move = false;
            $(id).fadeTo("fast", 1); //松开鼠标后停止移动并恢复成不透明
            $(id).css("border", "dotted 0px #0094ff");
            var data = {
              sensorid: clickId,
              locationx: xx,
              locationy: yy
            };
            that.axios.post("/detection/setsensorlocation", data).then(res => {
              if (res.data.code !== 0) {
                that.$message({
                  message: `更新传感器位置出错，错误码${
                    res.data.code
                  },请联系管理员`,
                  type: "warning"
                });
              }
            });
          }
        });
    }
    that.rtsocket = new WebSocket(`wss://${window.location.hostname.replace(".cn","")}:30000`);
    that.rtsocket.onopen = event => {
      // 通知wsServer当前浏览器的客户ID
      that.rtsocket.send(that.customerid);

      that.rtsocket.onmessage = msg => {
        if (msg.data.indexOf(",") < 0) {
          return;
        }
        console.log(msg.data);
        let {
          0: customerid,
          1: devicecode,
          2: sensorcode,
          3: temperature,
          4: humidity,
          5: flag
        } = msg.data.split(",");

        that.sensorslist.map(sensorInfo => {
          if (
            sensorInfo.sensorcode == sensorcode &&
            sensorInfo.devicecode == devicecode
          ) {
            sensorInfo.temperature = temperature;
            if (sensorInfo.devicekind == 0) {
              sensorInfo.humidity = humidity;
            }
            sensorInfo.flag = flag;
            sensorInfo.updatetime = new Date().format("mm-dd HH:MM:ss");
            id = "#" + sensor.sensorid;
            $(id).fadeTo(20, 0.5);
            $(id).fadeTo("slow", 1);
            if (temperflag != 0 && humiflag != 0)
              $(id).css({
                background: "url(/alarm.png) no-repeat right top"
              });
            if (temperflag != 0 && humiflag == 0)
              $(id).css({
                background: "url(/temperalarm.png) no-repeat right top"
              });
            if (temperflag == 0 && humiflag != 0)
              $(id).css({
                background: "url(/humialarm.png) no-repeat right top"
              });
            if (temperflag == 0 && humiflag == 0)
              $(id).css({
                background: "url(/green.png) no-repeat right top"
              });
          }
        });
      };
    };
  },
  destroyed() {
    this.destroySocket();
  }
};
</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #20a0ff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.danger {
  color: #ff4949;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

#map {
  min-height: 780px;
  background-color: #fff;
  background-repeat: no-repeat;
}
</style>